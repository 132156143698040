//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Header',
    data() {
        return {
			locale: 'EN', //使用i18n实例默认定义的语言
			locales: [
			    {
			        value: "EN",
			        label: "English",
			    },
			    {
			        value: "zh-CN",
			        label: "中文简体",
			    },
			    {
			        value: "zh-HK",
			        label: "中文繁體",
			    },
			],
			langs: ['zh-HK', 'en'],
            config: [
                // {label: '最新消息', path: '/newslist'},
                {label: this.$t('index'),locate:'index', path: '../home'},
                {label: this.$t('Login/Logon'), locate:'Login/Logon',path: '/login'},
                {label: this.$t('Myorders'),locate:'Myorders', path: '/user', type: '0'},
                {label: this.$t('Mycenter'),locate:'Mycenter', path: '/user'},
                {label: this.$t('Cart'),locate:'Cart', path: '/cart', type:'cart', class: 'active', icon: '&#xe70b;'},
            ],
            userInfo: null,
        }
    },
	created() {
	 
	    const locale = window.localStorage.getItem('locale') || this.$i18n.locale
	    this.locale = locale
	    this.$i18n.locale = locale
		
		console.log('locale:',locale);
	},
    methods: {
        goto(path, type='0') {
            if(path == '/login' && this.userInfo){
                this.setCookie('PHPSESSID', '', -1)
                this.$store.commit('saveUserInfo',null)
                this.$router.push({name:'Home'})
            }else{
                this.$router.push({path, query: {type,}})
            }   
        },
        setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+d.toUTCString();
            document.cookie = cname + "=" + cvalue + "; " + expires;

        },   
		changeLanguage(value) {
            this.locale = value
            window.localStorage.setItem('locale', value)
			   this.$i18n.locale = value
           window.location.reload()
        },
    },
    watch: {
        '$store.state.userInfo':function(info){
            this.userInfo = info
			console.log('info:',info);
            if(this.userInfo){
				console.log('login ok...');
                this.config[1].label =  this.$t('Logout')
                this.config[1].locate =  this.$t('Logout')
            }else{
                this.config[1].label = this.$t('Login/Logon')
            }   
            
        }
    }

}
