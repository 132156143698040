//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Footer',
    data() {
        return {
			html:"",	
				whatsapp:'',facebook:'',email:'',bootbakcolor:'',opentime:[],
			webset:[],
			id:'',
			uniacid:0,
			tabBarList3:[],
            config: [
                {label: '品種齊全，購物輕鬆', icon: '&#xe888;'},
                {label: '多倉直發，急速配送', icon: '&#xe69c;'},
                {label: '正品行貨，精緻服務', icon: '&#xe634;'},
                {label: '天天低價，畅選無憂', icon: '&#xe8c8;'},
            ],
			tel:'loading',
			qyname:'',
			address:'loading',
            tellConfig: [
                {label: 'Tel：', value: '+852 2138 3808'},
                {label: 'Address：', value: 'Unit 3, 10/F., Global Gateway Tower, No. 63 Wing Hong Street, Cheung Sha Wan, Kowloon, Hong Kong'},
            ]
        }
    },
	created(){
		this.getAutoLink()
		// this.html="<script type='text/javascript'"
		// this.html=this.html + ">var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();(function(){var s1=document.createElement('script'),s0=document.getElementsByTagName('script')[0];s1.async=true;s1.src='https://embed.tawk.to/62da098e37898912e95f082e/1g8hral8u';s1.charset='UTF-8';s1.setAttribute('crossorigin','*');s0.parentNode.insertBefore(s1,s0);})();<"
		// this.html=this.html + "/script>"
	},
	 methods: {
		 goto(id){
			 // this.$router.push({ path:url}) 
			 console.log(id);
			 this.id=id 
			 if (this.id.indexOf('wa.me') > -1) {
			 	console.log('go url');
			 	this.id = this.id.replace('/pages/webview/webview?url=','' )
			 	this.id = this.id.replace('%3A',':')
			 	this.id = this.id.replace('%2F','/')
			 	this.id = this.id.replace('%2F','/')
			 	this.id = this.id.replace('%2F','/')
			 	this.id = this.id.replace('%3D','=')
			 	this.id = this.id.replace('%3F','?')
			  
			 	console.log('id23:',this.id);
			 	 //window.location.href=
			 	 window.open(this.id,'_blank')
			 	return 
			 	}
			 
			   this.$router.push({ path:'/detail', query:{id}})
		 },
		 //获取自定义链接
		async getAutoLink(){
			
			var ss = window.localStorage.getItem('webinfo');
			var ssarray = JSON.parse(ss)
			console.log('ss',ss);
			console.log('ssarray.ucid',ssarray['ucid']);
			this.webset  =  ssarray
			this.uniacid =  ssarray['ucid']
			this.address =  ssarray['address']
			this.email =  ssarray['email']
			this.tel     =  ssarray['tel']
			this.qyname     =  ssarray['qyname']
			this.whatsapp     =  ssarray['whatsapp']
			this.facebook     =  ssarray['facebook']
			this.bootbakcolor     =  ssarray['bootbakcolor']
			this.opentime     =  ssarray['opentime'].split(',')
					
			const res = await  this.$http.get('/user/botmenu',{uniacid:this.uniacid})
			 console.log('555',res.data);
		
			this.tabBarList3 = res.data
			console.log('tab',this.tabBarList3);
		}
		 }
}
